import { useCloseConversation, useCurrentUserWorkspaceMember } from '~/hooks'
import { type ListConversationsResponse } from 'hitl-client/src/gen/client/operations/listConversations'
import { EmptyState, Button } from '~/elementsv2'
import { HITLChat } from './HITLChat'
import { useNavigate } from '@tanstack/react-router'

type Props = {
  downstreamConversation: ListConversationsResponse['conversations'][0]
  botId: string
  workspaceId: string
}

const HITLTabs = ({ downstreamConversation, botId, workspaceId }: Props) => {
  const currentWorkspaceMember = useCurrentUserWorkspaceMember({ workspaceId })
  const navigate = useNavigate()

  const isAssignedToMe = downstreamConversation.assignee?.workspaceMemberId === currentWorkspaceMember!.id
  const upstreamConversationId = downstreamConversation.tags['upstream']
  const { mutate: closeConversation, isPending: isSolvingTicket } = useCloseConversation({
    workspaceId,
    botId,
    onSuccess: () => void navigate({ to: '/workspaces/$workspaceId/bots/$botId/hitl', params: { botId, workspaceId } }),
  })

  return upstreamConversationId ? (
    <HITLChat
      className="h-full w-full"
      botId={botId}
      upstreamConversationId={upstreamConversationId}
      downstreamConversation={downstreamConversation}
      workspaceId={workspaceId}
      assignedToCurrentUser={isAssignedToMe}
    />
  ) : (
    <EmptyState
      title="Excalation Terminated"
      description="The user who requested the live chat session probably ended its conversation. You can safely mark as solved."
      primaryAction={
        <Button
          loading={isSolvingTicket}
          variant="soft"
          onClick={() => closeConversation({ conversationId: downstreamConversation.id })}
        >
          Mark as solved
        </Button>
      }
    />
  )
}

export { HITLTabs }
