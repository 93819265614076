import { WebchatProvider, useWebchatStore, type MessageObject } from '@botpress/webchat'
import { buildEggplantTheme } from '@botpress/webchat-generator'
import { blue } from '@radix-ui/colors'
import { Flex, IconButton, ScrollArea, Text, TextArea, Tooltip } from '@radix-ui/themes'
import { Event } from 'botpress-client'
import { PlusIcon } from 'lucide-react'
import { DateTime } from 'luxon'
import { forwardRef, useEffect, useMemo, useRef, useState, type ChangeEvent, type ComponentProps } from 'react'
import { HiMiniPaperAirplane, HiOutlineChatBubbleLeftRight } from 'react-icons/hi2'
import { useAsync, useInterval } from 'react-use'
import { getApiClientForBot } from '~/client'
import { Button, Card, EmptyState, Icon } from '~/elementsv2'
import { HITLClient } from '~/features/hitl/client'
import { useWebchatMessages } from '~/hooks'
import { useLocalStore } from '~/stores'
import { cn } from '~/utils'
import { ImageRenderer } from '../../conversations/components/ImageRenderer'
import { Message } from '../../conversations/components/Message'
import { Conversation } from 'hitl-client'

type MessageListProps = {
  botId: string
  upstreamConversationId: string
  workspaceId: string
  className?: string
  assignedToCurrentUser?: boolean
  downstreamConversation: Conversation
}

const HITLChat = ({
  botId,
  workspaceId,
  className,
  upstreamConversationId,
  assignedToCurrentUser,
  downstreamConversation,
}: MessageListProps) => {
  const getPreferredTheme = useLocalStore((s) => s.getPreferredTheme)
  const theme = getPreferredTheme()

  const { theme: themeLight, style: styleLight } = useMemo(
    () => buildEggplantTheme(blue.blue3, { twin: { preflight: false } }),
    []
  )
  const { theme: themeDark, style: styleDark } = useMemo(
    () => buildEggplantTheme(blue.blue3, { twin: { preflight: false }, darkMode: true }),
    []
  )

  const {
    data: fetchedMessages,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
  } = useWebchatMessages({
    botId,
    conversationId: upstreamConversationId,
    workspaceId,
  })

  const [eventHitlAssigned, setEventHitlAssigned] = useState<Event>()

  const [scrollHeight, setScrollHeight] = useState(0)
  const messages = fetchedMessages?.pages?.flatMap((page) => page.messages).reverse() ?? []

  const scrollableMessagesRef = useRef<HTMLDivElement>(null)

  useAsync(async () => {
    if (eventHitlAssigned?.conversationId === downstreamConversation.id) {
      return
    }

    const client = getApiClientForBot({
      botId,
      workspaceId,
    })

    const { events: hitlAssignedEvents } = await client.listEvents({
      type: 'hitl:hitlAssigned',
      conversationId: downstreamConversation.id,
    })

    setEventHitlAssigned(hitlAssignedEvents[0])
  }, [downstreamConversation.id, botId])

  useEffect(() => {
    const scrollableElement = scrollableMessagesRef.current

    if (scrollableElement && scrollHeight !== scrollableElement.scrollHeight) {
      const diff = scrollableElement.scrollHeight - scrollHeight

      if (diff) {
        scrollableElement.scrollTo({ top: 500 })
      }

      setScrollHeight(scrollableElement.scrollHeight)
    }
  }, [scrollableMessagesRef, messages.length, scrollHeight])

  useInterval(refetch, 1000)

  if (!isFetching && !messages.length) {
    return (
      <Card className={cn('flex items-center justify-center border-dashed', className)}>
        <EmptyState />
        <Flex align={'center'} gap={'2'} className="opacity-60">
          <Icon color="gray" size="4" icon={HiOutlineChatBubbleLeftRight} />
          <Text color="gray">No messages in conversation</Text>
        </Flex>
      </Card>
    )
  }

  return (
    <WebchatProvider isReadOnly renderers={{ image: ImageRenderer }} theme={theme === 'dark' ? themeDark : themeLight}>
      <ScrollArea scrollbars="vertical" className="h-[calc(100vh-15rem)]" ref={scrollableMessagesRef}>
        <Flex
          direction={'column'}
          gap={'2'}
          px={'8'}
          flexGrow={'1'}
          ref={scrollableMessagesRef}
          className={cn('mb-4 border-x-[1px] border-solid border-gray-6', className)}
        >
          <Button
            color="gray"
            variant="ghost"
            className="mx-auto my-2 w-fit"
            onClick={() => void fetchNextPage()}
            disabled={!hasNextPage}
          >
            {hasNextPage ? 'Load more' : 'End of conversation'}
          </Button>
          <Messages messages={messages} eventHitlAssigned={eventHitlAssigned} />

          <Card
            className={cn(
              { ['bg-dark-tremor-background']: theme === 'dark', ['bg-white']: theme === 'light' },
              'absolute bottom-0 m-auto h-[100px] w-[calc(100%-6rem)] rounded-xl shadow-lg'
            )}
          >
            <Composer
              readonly={downstreamConversation.status !== 'assigned' || !assignedToCurrentUser}
              workspaceId={workspaceId}
              botId={botId}
              downstreamConversationId={downstreamConversation.id}
            />
          </Card>
        </Flex>
      </ScrollArea>

      {theme === 'dark' ? <style>{styleDark}</style> : <style>{styleLight}</style>}
    </WebchatProvider>
  )
}

function Messages({ messages, eventHitlAssigned }: { messages: MessageObject[]; eventHitlAssigned?: Event }) {
  const [assignedAt, setAssignedAt] = useState<Date>()

  useEffect(() => {
    if (eventHitlAssigned?.createdAt) {
      setAssignedAt(new Date(eventHitlAssigned.createdAt))
    }
  }, [eventHitlAssigned])

  return (
    <>
      {messages?.map((message, i, arr) => (
        <div className={cn({ ['mb-24']: i === arr.length - 1 })}>
          <Message disableLink key={message.id} {...message} />
          {assignedAt &&
          assignedAt.getTime() > (message?.timestamp?.getTime() ?? 0) &&
          (!messages[i + 1] || assignedAt.getTime() < (messages[i + 1]?.timestamp?.getTime() ?? 0)) ? (
            <EscalatedMarker date={assignedAt} />
          ) : null}
        </div>
      ))}
    </>
  )
}

const EscalatedMarker = ({ date }: { date: Date }) => (
  <div className="my-3 mb-1 flex w-full items-center justify-center">
    <div className="ml-8 h-[1px] flex-grow bg-gray-8" />
    <div className="mx-4 text-sm text-gray-8">
      Conversation Escalated &#8226; {DateTime.fromJSDate(date).toRelative()}
    </div>
    <div className="mr-8 h-[1px] flex-grow bg-gray-8" />
  </div>
)

type ComposerParams = {
  workspaceId: string
  botId: string
  downstreamConversationId: string
  readonly: boolean
}

const Composer = ({ botId, workspaceId, downstreamConversationId, readonly }: ComposerParams) => {
  const client = useHitlClient({ botId, workspaceId })
  const { composer } = useWebchatStore((s) => s.theme)

  const [value, setValue] = useState('')

  const ref = useRef<HTMLTextAreaElement>(null)
  const [shiftHeld, setShiftHeld] = useState(false)

  function downHandler({ key }: { key: string }) {
    if (key === 'Shift') {
      setShiftHeld(true)
    }
  }

  function upHandler({ key }: { key: string }) {
    if (key === 'Shift') {
      setShiftHeld(false)
    }
  }

  useAutosizeTextArea(ref.current, value)

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  if (!client) {
    return null
  }

  const sendMessage = async () => {
    if (value.trim()) {
      setValue('')
      await client.sendMessage(value, downstreamConversationId).catch((error) => {
        console.error('Error sending message:', error)
      })
    }
  }

  return (
    <div className="flex w-full justify-center gap-2 p-3">
      <Tooltip content={'Attach Content'} hidden={readonly}>
        <UploadButton
          sendFile={async (file) => client.sendFile(file, downstreamConversationId)}
          disabled={readonly}
          className="self-end"
        />
      </Tooltip>
      <TextArea
        variant="soft"
        {...composer?.input}
        disabled={readonly}
        className={cn(composer?.input?.className, '!m-0 w-full resize-none')}
        placeholder={readonly ? 'You must assign yourself to interact with this conversation.' : 'Type your message...'}
        ref={ref}
        value={value}
        data-has-value={!!value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()

            if (shiftHeld) {
              setValue(`${value}\n`)
              setTimeout(() => ref.current?.scroll({ behavior: 'instant', top: ref.current.scrollHeight }), 0)
            } else {
              void sendMessage()
            }
          }
        }}
      />
      <IconButton className="self-end" disabled={readonly} onClick={() => void sendMessage()} color="green">
        <HiMiniPaperAirplane size={18} />
      </IconButton>
    </div>
  )
}

type UseHitlClientParams = {
  botId: string
  workspaceId: string
}

const useHitlClient = ({ botId, workspaceId }: UseHitlClientParams) => {
  const [client, setClient] = useState<HITLClient>()

  useAsync(async () => {
    if (client?.workspaceId !== workspaceId || client.botId !== botId) {
      const newClient = await HITLClient.create({
        botId,
        workspaceId,
      })

      setClient(newClient)
    }
  }, [botId, workspaceId])

  return client
}

type UploadButtonProps = {
  sendFile: (file: File) => ReturnType<HITLClient['sendFile']>
} & ComponentProps<'button'>
const UploadButton = forwardRef<HTMLButtonElement, UploadButtonProps>(({ sendFile, ...props }, ref) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click()
    }
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      await sendFile(file).catch((error) => {
        console.error('Error sending file:', error)
      })
    }
  }

  return (
    <>
      {/* @ts-ignore I don't have time to fix this typing issue.. it works */}
      <IconButton ref={ref} {...props} onClick={handleClick} variant="soft" color="gray" radius="full" size={'1'}>
        <PlusIcon size={16} />
      </IconButton>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={(e) => void handleFileChange(e)} />
    </>
  )
})

const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string, maxHeight = 120) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = '0px'
      const scrollHeight = value ? Math.min(textAreaRef.scrollHeight, maxHeight) : 36

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = `${scrollHeight}px`
    }
  }, [textAreaRef, value])
}

export { HITLChat }
