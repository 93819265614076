import { Badge, Flex, Separator, Text, Tooltip } from '@radix-ui/themes'
import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'

import type { Conversation } from 'hitl-client'
import { HiOutlineArrowUturnRight, HiOutlineUser } from 'react-icons/hi2'
import { UserBadge } from '~/componentsV2'
import { Button, DataListItem, DataListRoot } from '~/elementsv2'
import { ConversationSummary } from '~/features/conversations/components/ConversationSumary'
import {
  useConversationParticipants,
  useCurrentUserWorkspaceMember,
  useSetAssignee,
  useCloseConversation,
} from '~/hooks'
import { listConversationsInfiniteQuery } from '~/queries'
import { useSuspenseQuery } from '~/services'
import { cn } from '~/utils'

type Props = {
  className?: string
  downstreamConversation: Conversation
  botId: string
  workspaceId: string
}

export const ConversationDetails = ({ className, downstreamConversation, botId, workspaceId, ...props }: Props) => {
  const navigate = useNavigate()
  const upstreamConversationId = downstreamConversation.tags['upstream']!
  const participants = useConversationParticipants({
    botId,
    conversationId: upstreamConversationId,
    workspaceId,
  }).data

  const { conversation: upstreamConversation } = useSuspenseQuery(
    'workspaces_/$workspaceId_/bots_/$botId_/$conversationId_',
    { botId, conversationId: upstreamConversationId, workspaceId }
  ).data

  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data
  const currentWorkspaceMember = useCurrentUserWorkspaceMember({ workspaceId })!

  const assigneeId = downstreamConversation.assignee?.workspaceMemberId
  const assignee = workspaceMembers?.find((member) => member.id === assigneeId)

  const shownParticipants = participants?.slice(0, 10) ?? []
  const hiddenParticipants = participants?.slice(10) ?? []

  const isAssignedToMe = assigneeId === currentWorkspaceMember.id

  const { mutate: setAssignee, isPending: isSettingAssignee } = useSetAssignee({ workspaceId, botId })
  const { mutate: closeConversation, isPending: isSolvingTicket } = useCloseConversation({
    workspaceId,
    botId,
    onSuccess: () => void navigate({ to: '/workspaces/$workspaceId/bots/$botId/hitl', params: { botId, workspaceId } }),
  })

  //this is not ideal, we assume that the 1st participant is the main one
  const conversationsWithSameParticipant = useSuspenseInfiniteQuery(
    listConversationsInfiniteQuery({ botId, workspaceId, participantIds: [shownParticipants[0]!.id] })
  )
    .data.pages.flatMap((page) => page.conversations)
    .filter(
      (conv) =>
        conv.id !== downstreamConversation.id && conv.id !== upstreamConversationId && conv.integration !== 'hitl'
    )
    .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
    .slice(0, 5)

  return (
    <Flex direction={'column'} gap={'4'}>
      <DataListRoot orientation={'vertical'} {...props} className={cn(className)}>
        <DataListItem label="Conversation">
          <ConversationSummary conversation={upstreamConversation} {...{ botId, workspaceId }} className={'w-full'} />
        </DataListItem>
        <DataListItem label="Participants">
          <Flex gap={'2'} wrap="wrap">
            {shownParticipants.map((participant) => (
              <UserBadge
                key={participant.id}
                variant="soft"
                avatarName={participant.name ?? `Anonymous User ${participant.id}`}
                name={participant.name ?? 'Anonymous User'}
                pictureUrl={participant.pictureUrl}
              />
            ))}
            {hiddenParticipants.length > 0 && (
              <Badge variant="outline" color="gray">
                <Flex gap={'2'} align={'center'}>
                  <Text>+ {hiddenParticipants.length} more</Text>
                </Flex>
              </Badge>
            )}
          </Flex>
        </DataListItem>
        {conversationsWithSameParticipant.length > 0 && (
          <DataListItem label="More conversations from user">
            <Flex direction={'column'} gap={'2'} width={'full'}>
              {conversationsWithSameParticipant.map((conv) => (
                <ConversationSummary
                  key={conv.id}
                  conversation={conv}
                  botId={botId}
                  workspaceId={workspaceId}
                  className={'w-full'}
                />
              ))}
            </Flex>
          </DataListItem>
        )}
        {assignee && (
          <DataListItem label="Assigned">
            <UserBadge
              variant="soft"
              name={assignee.displayName ?? assignee.email}
              pictureUrl={assignee.profilePicture}
            />
          </DataListItem>
        )}
      </DataListRoot>
      <Separator size={'4'} />

      {downstreamConversation.status === 'assigned' && isAssignedToMe && (
        <Tooltip content="This will mark the conversation as solved and will handover the control back to chatbot">
          <Button
            trailing={<HiOutlineArrowUturnRight />}
            onClick={() => {
              closeConversation({ conversationId: downstreamConversation.id })
            }}
            loading={isSolvingTicket}
          >
            <span>Resume Chatbot</span>
          </Button>
        </Tooltip>
      )}
      {downstreamConversation.status === 'pending' && (
        <Button
          disabled={isSettingAssignee}
          variant="soft"
          className="!bg-grass-3 hover:!bg-grass-4"
          color="grass"
          size={'2'}
          leading={<HiOutlineUser />}
          onClick={() =>
            setAssignee({ conversationId: downstreamConversation.id, workspaceMemberId: currentWorkspaceMember.id })
          }
        >
          <span>Assign to me</span>
        </Button>
      )}
    </Flex>
  )
}
