import { Flex, ScrollArea, Select, Separator, Text, Tooltip } from '@radix-ui/themes'
import { useSuspenseInfiniteQuery, useSuspenseQuery as useSuspenseTanstack } from '@tanstack/react-query'
import { Link, useSearch } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { Fragment, useState } from 'react'
import { Avatar, Button, Icon } from '~/elementsv2'
import { StatusBadge } from '~/features/hitl/components/StatusBadge'
import { isConversationStatus } from '~/features/hitl/types'
import { useConversationParticipants, useCurrentUserWorkspaceMember } from '~/hooks'
import {
  getIntegrationByNameQueryOptions,
  listConversationsInfiniteQuery,
  listMessagesInfiniteQueryOptions,
} from '~/queries'
import { useSuspenseQuery } from '~/services'
import { cn } from '~/utils'
import type { Conversation as ConversationObject } from 'hitl-client'
import { PiUserCircleDashed, PiUsers, PiUserCircle } from 'react-icons/pi'

type Props = {
  className?: string
  currentConversationId?: string
  downstreamConversations: ConversationObject[]
} & Parameters<typeof listConversationsInfiniteQuery>[0]

const ASSIGNMENT_FILTERS = {
  me: { label: 'Assigned to me', icon: <PiUserCircle size={20} /> },
  pending: { label: 'Unassigned', icon: <PiUserCircleDashed size={20} /> },
  all: { label: 'All', icon: <PiUsers size={20} /> },
} as const

type AssignmentFilterValue = keyof typeof ASSIGNMENT_FILTERS

const STATUS_FILTER_OPTIONS = ['open', 'closed'] as const
type StatusFilterValue = (typeof STATUS_FILTER_OPTIONS)[number]

export const ConversationList = ({
  className,
  botId,
  workspaceId,
  currentConversationId,
  downstreamConversations,
  ...props
}: Props) => {
  const currentWorkspaceMember = useCurrentUserWorkspaceMember({ workspaceId })

  const [assigneeFilter, setAssigneeFilter] = useState<AssignmentFilterValue>('me')
  const [statusFilter, setStatusFilter] = useState<StatusFilterValue>('open')

  const filteredConversations = downstreamConversations.filter((conversation) => {
    let display = true
    const conversationOpen = ['assigned', 'pending'].includes(conversation.status)
    if ((statusFilter === 'open' && !conversationOpen) || (statusFilter === 'closed' && conversationOpen)) {
      display = false
    }
    if (assigneeFilter === 'me' && conversation.assignee?.workspaceMemberId !== currentWorkspaceMember?.id) {
      display = false
    }
    if (assigneeFilter === 'pending' && conversation.assignee) {
      display = false
    }
    return display
  })

  return (
    <Flex gap={'2'} direction={'column'} className="min-w-0">
      <Flex gap={'2'} wrap={'wrap'} align={'center'} justify={'between'}>
        <Select.Root value={assigneeFilter} onValueChange={(v: AssignmentFilterValue) => setAssigneeFilter(v)}>
          <Tooltip content="Filter by assignee" delayDuration={750}>
            <Select.Trigger variant="surface">
              <Flex gap={'2'} align={'center'}>
                {ASSIGNMENT_FILTERS[assigneeFilter].icon}
                <span>{ASSIGNMENT_FILTERS[assigneeFilter].label}</span>
              </Flex>
            </Select.Trigger>
          </Tooltip>
          <Select.Content>
            {Object.entries(ASSIGNMENT_FILTERS).map(([key, { label, icon }]) => (
              <Select.Item key={key} value={key}>
                <Flex gap={'1'} align={'center'}>
                  {icon}
                  <span>{label}</span>
                </Flex>
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
        <Select.Root value={statusFilter} onValueChange={(v: StatusFilterValue) => setStatusFilter(v)}>
          <Tooltip content="Filter by status" delayDuration={750}>
            <Select.Trigger variant="ghost" color="gray">
              <Flex gap={'2'} align={'center'}>
                <span className="capitalize">{statusFilter}</span>
              </Flex>
            </Select.Trigger>
          </Tooltip>
          <Select.Content>
            {STATUS_FILTER_OPTIONS.map((v) => (
              <Select.Item key={v} value={v}>
                <Text className="capitalize">{v}</Text>
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </Flex>
      <ScrollArea scrollbars="vertical" className="h-[calc(100vh-18rem)] [&>div>div]:!w-auto">
        <Flex direction={'column'} gap={'1'} {...props} className={className} width={'full'}>
          {filteredConversations.length === 0 ? (
            <Flex direction={'column'} py={'5'} gap={'3'}>
              <Text size={'2'} weight={'medium'} align={'center'}>
                {statusFilter === 'open'
                  ? 'No open conversations, your bot is doing a great job!'
                  : 'No closed conversations yet.'}
              </Text>
              {assigneeFilter !== 'all' && (
                <Button variant="soft" size={'2'} onClick={() => setAssigneeFilter('all')}>
                  Show all
                </Button>
              )}
            </Flex>
          ) : (
            filteredConversations.map((conversation, i) => (
              <ConversationListItem
                key={conversation.id}
                downstreamConversation={conversation}
                botId={botId}
                workspaceId={workspaceId}
                index={i}
                selected={currentConversationId === conversation.id}
              />
            ))
          )}
        </Flex>
      </ScrollArea>
    </Flex>
  )
}

type ConversationProps = {
  botId: string
  workspaceId: string
  downstreamConversation: ConversationObject
  index: number
  selected: boolean
}

const ConversationListItem = ({ selected, index, downstreamConversation, ...workspaceAndBotId }: ConversationProps) => {
  const searchParams = useSearch({ strict: false })
  const upstreamConversationId = downstreamConversation.tags['upstream']!

  const upstreamConversation = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/$conversationId_', {
    conversationId: upstreamConversationId,
    ...workspaceAndBotId,
  }).data.conversation

  const conversationIdFilter =
    downstreamConversation.status === 'pending' ? upstreamConversationId : downstreamConversation.id
  const directionFilter = downstreamConversation.status === 'pending' ? 'incoming' : 'outgoing'

  const lastUserMessage = useSuspenseInfiniteQuery(
    listMessagesInfiniteQueryOptions({
      conversationId: conversationIdFilter,
      ...workspaceAndBotId,
    })
  )
    .data.pages?.flatMap((page) => page.messages)
    .filter((message) => message.direction === directionFilter && message.type === 'text')
    .shift()

  const participants = useConversationParticipants({
    conversationId: upstreamConversationId,
    ...workspaceAndBotId,
  }).data

  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', workspaceAndBotId).data

  // TODO: this is not ideal, this will not work with multiple participants, we assume the 1st participant is the main one
  const user = participants[0]

  const userName = user?.name ?? 'Anonymous User'
  const hitlStatus = isConversationStatus(downstreamConversation.status) ? downstreamConversation.status : 'unknown'

  const assigneeId = downstreamConversation.assignee?.workspaceMemberId
  const assignee = workspaceMembers?.find((member) => member.id === assigneeId)
  const integration = useSuspenseTanstack(
    getIntegrationByNameQueryOptions({ name: upstreamConversation.integration })
  ).data

  const lastMessageSentTimeStr = DateTime.fromISO(lastUserMessage?.createdAt ?? '')
    .toRelative({ style: 'narrow' })
    ?.replace(/\.?\sago/, '')

  return (
    <Fragment key={downstreamConversation.id}>
      {index !== 0 && <Separator size={'4'} />}
      <Link
        to="/workspaces/$workspaceId/bots/$botId/hitl"
        className={cn('p-3', selected ? 'bg-gray-3' : 'hover:bg-gray-2')}
        params={workspaceAndBotId}
        search={{ ...searchParams, conversationId: downstreamConversation.id }}
        preload={false}
      >
        <Flex gap={'2'} className="text-sm">
          <Avatar variant="soft" size={'1'} pictureUrl={user?.pictureUrl} name={userName + user?.id} radius="full" />
          <Flex direction={'column'} className="min-w-0" flexGrow={'1'} gap={'2'}>
            <Flex align={'center'} gap={'2'}>
              <Text className="truncate pr-1 font-medium">{userName}</Text>
              <Flex ml={'auto'} align={'center'} gap={'1'}>
                <StatusBadge status={hitlStatus} />
                <Tooltip content={`Conversation occurs on ${integration.name}`}>
                  <Icon
                    size="2"
                    variant={'surface'}
                    color={'gray'}
                    icon={(props) => <img src={integration.iconUrl} {...props} />}
                  />
                </Tooltip>
                {assignee && (
                  <Tooltip content={`Assigned to ${assignee.displayName ?? assignee.email}`}>
                    <Avatar
                      size={'1'}
                      className="-mt-px h-4 w-4"
                      name={assignee.email}
                      pictureUrl={assignee.profilePicture}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
            <Flex align={'center'} gap={'1'}>
              <Text size={'1'} className="line-clamp-3 max-w-[80%] truncate">
                {lastUserMessage?.payload['text'] ?? ' '}
              </Text>
              <Text size={'1'} weight={'light'} color={'gray'} ml={'auto'}>
                {lastMessageSentTimeStr}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Fragment>
  )
}
